import { Layout, Skeleton, Modal, Row, Col, Space } from "antd";
import firebase from "firebase";
import {
  RemoteMongoClient, Stitch, UserPasswordCredential, AnonymousCredential
} from "mongodb-stitch-browser-sdk";
import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./components/Footer";
import Login from "./components/Login";
import LoginEmerging from "./components/LoginEmerging";
import Navigation from "./components/Navigation";
import Sider from "./components/Sider";
import Profile from "./components/Profile";
import { config } from "./config";
import { hasWindow } from "./js/utils";
import * as Sentry from "@sentry/react";
//import { browserName } from "react-device-detect"
import "./styles/App.scss";

const Pro = lazy(() => import('./components/pages/Coravin'));
const ProEnthusiast = lazy(() => import('./components/pages/CoraEnthusiast'));
const Landing = lazy(() => import('./components/main/Landing'));
const MainOriginal = lazy(() => import('./components/main/Main'));
const MainEmerging = lazy(() => import('./components/main/MainEmerging'));
const CRM = lazy(() => import('./components/main/CRM'));
const NationalAccountMongo = lazy(() => import('./components/main/NationalAccountsMongo'));
const HomeOriginal = lazy(() => import('./components/main/Home'));
const HomeEmerging = lazy(() => import('./components/main/HomeEmerging'));
const Dashboard = lazy(() => import('./components/main/Dashboard'));
const Distributors = lazy(() => import('./components/main/Distributors'));
const MainRestaurant = lazy(() => import('./components/main/MainRestaurant'));
const Groups = lazy(() => import('./components/main/Groups'));
const Awards = lazy(() => import('./components/main/Awards'));
const Suppliers = lazy(() => import('./components/main/Suppliers'));
const Supplier = lazy(() => import('./components/main/Supplier'));
const Policies = lazy(() => import('./components/pages/policies'));
const Simone = lazy(() => import('./components/main/components/SearchSummarySimone'));

function* find_end(dct, keys = []) {
  for (let [i, j] of Object.entries(dct)) {
      if (typeof j === 'object' && j !== null) {
          for (let t of find_end(j, keys.concat(i))) {
              yield t;
          }
      } else {
          yield keys;
      }
  }
}
function removeElements(dct, path, count) {
  let skipNext = false;
  let nested_ddelete_count = 0;
  let filter_delete_count = 0;
  let reverse_nested_delete_count = 0;
  let currentDict = dct;
  for (let i of path) {
      if (skipNext) {
          skipNext = false;
          continue;
      }
      if (i === 'nested_ddelete') {
          nested_ddelete_count++;
          if (nested_ddelete_count > 1) {
              removeElement(currentDict, 'nested_ddelete');
              skipNext = true;
              continue;
          }
      }
      if (i === 'filter_delete') {
          filter_delete_count++;
          if (filter_delete_count > 1) {
              removeElement(currentDict, 'filter_delete');
              skipNext = true;
              continue;
          }
      }
      if (i === 'reverse_nested_delete') {
          reverse_nested_delete_count++;
          if (reverse_nested_delete_count < count) {
              removeElement(currentDict, 'reverse_nested_delete');
              skipNext = true;
              continue;
          }
      }
      console.log(currentDict, i);
      currentDict = currentDict[i];
  }
}

function removeElement(dct, key) {
  let removed = dct[key];
  delete dct[key];
  for (let [i, j] of Object.entries(removed['aggs'])) {
      dct[i] = j;
  }
}

function removeNestedElements(aggs) {
  while (true) {
      let found = false;
      let pathGenerator = find_end(aggs['aggs']);
      let nextPath = pathGenerator.next();
      while (!nextPath.done) {
          let path = nextPath.value;
          console.log(path);
          let nested_ddelete_count = path.filter(x => x === 'nested_ddelete').length;
          let filter_delete_count = path.filter(x => x === 'filter_delete').length;
          let reverse_nested_delete_count = path.filter(x => x === 'reverse_nested_delete').length;
          if (reverse_nested_delete_count >= 2) {
              removeElements(aggs['aggs'], path, reverse_nested_delete_count);
              found = true;
              break;
          }
          nextPath = pathGenerator.next();
      }
      if (!found) {
          break;
      }
  }
}



const settings = {
  wine: {
    type: "wine",
    likelyType: [
      ["isWineP", 25],
      ["isSweetP", 25],
      ["isSakeP", 25],

    ],
    priceField: "pr.sizeCurrAdjPrice",
    titlePlural: "Wines",
    isBaseFilter: true,
    isBodyCollapseFilter: true,
    index: "abottle-*-test-alias",
  },
  everything: {
    type: "everything",
    logoTag: "R Beta",
    likelyType: [
      ["isWineP", 25],
      ["isSweetP", 25],
      ["isSpiritP", 25],
      ["isOtherP", 25],
      ["isSakeP", 25],
      ["isBeerP", 25],
    ],
    priceField: "pr.sizeCurrAdjPrice",
    titlePlural: "Items",
    isBaseFilter: true,
    isBodyCollapseFilter: true,
    index: "abottle-*-test-alias",
  },
  spirits: {
    type: "spirits",
    likelyType: [
      ["isSpiritP", 15]
    ],
    priceField: "pr.price",
    logoTag: "Spirits Pro BETA",
    titlePlural: "Spirits",
    isBaseFilter: true,
    isBodyCollapseFilter: true,
    index: "abottle-*-test-alias",
  },
  showAll: {
    type: "showAll",
    logoTag: "R Beta",
    likelyType: [
      ["isWineP", 25],
      ["isSweetP", 25],
      ["isSpiritP", 25],
      ["isOtherP", 25],
      ["isSakeP", 25],
      ["isBeerP", 25],
    ],
    priceField: "pr.sizeCurrAdjPrice",
    titlePlural: "Items",
    isBaseFilter: false,
    isBodyCollapseFilter: false,
    index: "abottle-*-sgws-000001",
  },
  eu: {
    type: "wine",
    logoTag: "eu",
    countries: ['GB', 'FR', 'DE', 'CH', 'AT'],
    likelyType: [
      ["isWineP", 25],
      ["isSweetP", 25],
      ["isSakeP", 25],
    ],
    priceField: "pr.sizeCurrAdjPrice",
    showWineStyles: true,
    titlePlural: "Wines",
    isBaseFilter: true,
    isBodyCollapseFilter: false,
    index: "abottle-*-david-0053",
  },
  somm3: {
    type: "wine",
    logoTag: "v3",
    likelyType: [
      ["isWineP", 15],
      //["isSweetP",15],
      //["isSpiritP",25],
      //["isOtherP",25],
      //["isSakeP",25],
      //["isBeerP",25],
    ],
    priceField: "pr.sizeCurrAdjPrice",
    showUsData: true,
    showWineStyles: true,
    titlePlural: "Wines",
    isBaseFilter: true,
    isBodyCollapseFilter: false,
    index: "abottle-*-david-0024",
    debug: true,
  },
  sgws3: {
    type: "wine",
    logoTag: "sgws",
    likelyType: [
      ["isWineP", 15],
      ["isSweetP", 15],
      //["isSpiritP",25],
      //["isOtherP",25],
      ["isSakeP", 15],
      //["isBeerP",25],
    ],
    priceField: "pr.sizeCurrAdjPrice",
    showUsData: true,
    showSourceFilter: true,
    showWineStyles: true,
    titlePlural: "Wines",
    isBaseFilter: true,
    isBodyCollapseFilter: false,
    index: "abottle-*-sgwsny-0026,abottle-*-david-0024",
    debug: false,
  },
  sgwsSpirits3: {
    type: "spirits",
    logoTag: "sgws",
    likelyType: [
      //["isWineP",15],
      //["isSweetP",15],
      ["isSpiritP", 15],
      //["isOtherP",25],
      //["isSakeP",15],
      //["isBeerP",25],
    ],
    priceField: "pr.price",
    showUsData: true,
    logoTag: "sgws-s",
    titlePlural: "Spirits",
    showSourceFilter: true,
    isBaseFilter: true,
    isBodyCollapseFilter: false,
    index: "abottle-*-sgwsny-0026,abottle-*-david-0024",
    debug: false,
  },
  spirits3: {
    type: "spirits",
    likelyType: [
      ["isSpiritP", 15]
    ],
    priceField: "pr.price",
    showUsData: true,
    logoTag: "Spirits Pro BETA",
    titlePlural: "Spirits",
    isBaseFilter: true,
    isBodyCollapseFilter: true,
    index: "abottle-*-david-0024",
    debug: false,
  },
  retail: {
    type: "retail",
    logoTag: "retail",
    //countries: ['GB','FR', 'DE'],
    likelyType: [
      ["isWineP", 15],
      //["isSweetP",15],
      //["isSpiritP",25],
      //["isOtherP",25],
      //["isSakeP",25],
      //["isBeerP",25],
    ],
    priceField: "pr.price",
    showWineStyles: true,
    titlePlural: "Wines",
    isBaseFilter: true,
    isBodyCollapseFilter: false,
    index: "abottle-*-retail-0002",
  },
  wineNew: {
    type: "wine",
    likelyType: [
      ["isWineP", 25],
      ["isSweetP", 25],
      ["isSakeP", 25],
    ],
    priceField: "pr.sizeCurrAdjPrice",
    titlePlural: "Wines",
    isBaseFilter: true,
    isBodyCollapseFilter: true,
    index: "abottle-*-david-0053",
    countries: ['US'],
    showUsData: true,
  },
  beerNew: {
    type: "spirits",
    likelyType: [
      ["isBeerP", 15],
    ],
    priceField: "pr.sizeCurrAdjPrice",
    titlePlural: "Beers",
    isBaseFilter: true,
    isBodyCollapseFilter: true,
    index: "abottle-*-david-0053",
    countries: ['US'],
    showUsData: true,
  },
  wineNewCa: {
    type: "wine",
    likelyType: [
      ["isWineP", 25],
      ["isSweetP", 25],
      ["isSakeP", 25],
    ],
    priceField: "pr.sizeCurrAdjPrice",
    titlePlural: "Wines",
    isBaseFilter: true,
    isBodyCollapseFilter: true,
    index: "abottle-*-david-0053",
    countries: ['CA'],
    showUsData: true,
  },
  spiritsNew: {
    type: "spirits",
    likelyType: [
      ["isSpiritP", 15]
    ],
    priceField: "pr.price",
    logoTag: "Spirits Pro BETA",
    titlePlural: "Spirits",
    isBaseFilter: true,
    isBodyCollapseFilter: true,
    index: "abottle-*-david-0053",
    countries: ['US'],
    showUsData: true,
  },
  spiritsNewAsia: {
    type: "spirits",
    likelyType: [
      ["isSpiritP", 15]
    ],
    priceField: "pr.price",
    logoTag: "Spirits Pro BETA",
    titlePlural: "Spirits",
    isBaseFilter: true,
    isBodyCollapseFilter: true,
    index: "abottle-*-david-0053",
    countries: ['SG'],
    showUsData: true,
  },
  spiritsNewEu: {
    type: "spirits",
    likelyType: [
      ["isSpiritP", 15]
    ],
    priceField: "pr.price",
    logoTag: "Spirits Pro BETA",
    titlePlural: "Spirits",
    isBaseFilter: true,
    isBodyCollapseFilter: true,
    index: "abottle-*-david-0053",
    countries: ['GB', 'FR', 'DE', 'CH', 'AT'],
    showUsData: true,
  },
  
  foodNew: {
    type: "food",
    likelyType: [
      ["isWineP", 15],
      ["isSweetP", 15],
      ["isSakeP", 15],
      ["isFoodP", 15],
      ["isSpiritP", 15],
      ["isBeerP", 15],
    ],
    priceField: "pr.price",
    titlePlural: "Wines",
    isBaseFilter: true,
    isBodyCollapseFilter: true,
    index: "abottle-*-david-0053",
    countries: ['US', 'CA'],
    showUsData: true,
  },
  everythingNew: {
    type: "everything",
    likelyType: [
      ["isWineP", 15],
      ["isSweetP", 15],
      ["isSakeP", 15],
      ["isFoodP", 15],
      ["isSpiritP", 15],
      ["isBeerP", 15],
    ],
    priceField: "pr.price",
    titlePlural: "Wines",
    isBaseFilter: true,
    isBodyCollapseFilter: true,
    index: "abottle-*-david-0053",
    countries: ['US'],
    showUsData: true,
  },
}

const baseComponents = [
  'filter_by_restaurant',
  'colour-component',
  'geo-search',
  'grape-component',
  'price-component',
  'region-component',
  'sgws-search',
  'suntory-search',
  'allied-search',
  'overproof-search',
  'state-component',
  'type-component',
  'w',
  'supplier-component',
  'size-component',
  'size-specific-component',
  'distributor-producers',
  'distributor-restaurants',
  'producer-by-search-component',
  'vintage-single-component',
  'wine-by-search-component',
  'wine-component-2',
  'closed-component',
  'currently-active-component',
  'chain-component',
  'date-component',
  'date-component-2',
  'class-component',
  'class-component-2',
  'country-component',
  'json-sgws',
  'spiritType-component',
  'sgws-switch-component',
  'drinkType-component',
  'res-cat-component',
  'distributor-does-business-with',
  'distributor-has-producer',
  'distributor-does-business-with-all-distributors',
  'btg-component',
  'zip-component',
  'res-price-component',
  'awards-component',
  'skus-component',
  'restaurant-group-component',
  'certifications-component',
  'metro-component',
  'hobbs-component',
  'gallo-component',
  'terlato-component',
  'crown-component',
  'food-type-component',
  //'airport-component',
  'persistant-geo',
  'treeListSensor',
  //'nested-component',
  'allied-component',
]

const baseFilter = DRINKTYPE => lst => () => ({
  query: {
    bool: {
      "minimum_should_match": process.env.REACT_APP_DRINKTYPE == "foodNew" ? 0 : 1,
      "should": lst.map(([x, y]) => ({
        range: {
          [`wineLength.distinctQuantities.${x}.${y}`]:
            { "gte": 3 }
        }
      })),
      "must": [
        {
          terms: {
            "likelyType": lst.map(([x, y]) => x)
          }
        },
        {
          "exists":{
            "field":"res2.alias"
          }
        },
        {
          terms: {
            "g.types":[ "restaurant", "bar", "bakery", "cafe", "lodging"]
          }
        },
      ],
      "must_not": [
        {
          "terms": {
            "comparison_result": [ false ],
          }
        },
        {
          "terms": {
            "scrapeData.special": [ "na-openai"],
          }
        },
        {
          "terms": {
            "rid": [ "vin-48-avon"],
          }
        },
        {
          "term":{
            "res2.alias": ""
          }
        },
        {
          "bool": {
            "must": [
              
              {
                "exists": {
                  "field": "httpError"
                }
              },
              {
                "terms": {
                  "_index": ["abottle-active-david-*"]
                }
              }
            ]
          }
        },
        {
          terms: {
            "res.is_closed": [true]
          }
        },
        {
          terms: {
            "g.business_status": ["CLOSED_PERMANENTLY"]
          }
        },
        {
          terms: {
            "scrapeData.from.ascii": ["product", "products", "collection", "collections",]
          }
        },
        {
          bool: {
            must: [
              {
                terms: {
                  "scrapeData.url.ascii": ["collection", "collections", "product", "products"]
                }
              },
              {
                terms: {
                  "filetype": ["html",]
                }
              },
            ]
          }
        },
      ]
    }
  }
})

if (!firebase.apps.length) {
  firebase.initializeApp(config);
  firebase.analytics();

}






class SignInScreen extends React.Component {
  constructor(props) {
    super(props);

    this.cart = React.createRef();

  }
  // The component's Local state.
  state = {
    isSignedIn: !!firebase.auth().currentUser, // Local signed-in state.
    isPro: null,
    google: false,
    city: "",
    wine: null,
    restaurant: null,
    distributor: null,
    filenames: [],
    downloadURLs: [],
    isUploading: false,
    uploadProgress: 0,
    refresh: true,
    nearByLocation: null,
    buy: window.location.pathname.split("/places").includes("shop") || false,
    DistributorStates: [],
    DistributorProducers: {},
    defaultRestaurants: {},
    DistributorTeam: [],
    nearByLocationArray: [],
    hasPhone: true,
    radius: 10,
    DRINKTYPE: 'wineNew',
  };

  setnearByLocation = (x) => {
    if (firebase.auth().currentUser) {
      firebase
        .database()
        .ref("users/")
        .child(firebase.auth().currentUser.uid)
        .update({ "cityPreferenceCoordinates": x });
    }
    this.setState({
      nearByLocation: x,
      nearByLocationArray: [x],
    })
    if (!x) {
      this.setCity(null)
    } else {
      this.setDistributorStatesState([])
    }
  }

  setRadius = (radius) => this.setState({ radius: radius })


  setnearByLocationArray = (x) => {
    this.setState({
      nearByLocationArray: [...this.state.nearByLocationArray, x]
    })
  }


  setnearByLocationArrayTogether = (x) => {
    this.setState({
      nearByLocationArray: x
    })
  }

  resetLocation = () => {
    this.setState({
      city: "",
      nearByLocation: null,
      nearByLocationArray: [null],
    });
  }


  setCity = (city) => {
    this.setState({ city: city });
    if (firebase.auth().currentUser) {
      firebase
        .database()
        .ref("users/")
        .child(firebase.auth().currentUser.uid)
        .update({ "cityPreference": city });
    }
  }


  hasPhoneCheck = () => {
    if (firebase.auth().currentUser) {
      firebase.database().ref(`/users/${firebase.auth().currentUser.uid}/hubspot/phone`).on('value', value => {
        if (value.val() !== null) {
          this.setState({ hasPhone: value.val() })
        } else {
          this.setState({ hasPhone: false })
        }
      })
      firebase.database().ref(`/users/${firebase.auth().currentUser.uid}/role`).on('value', value => {
        if (value.val() !== null) {
          this.setState({ role: value.val() })
        } else {
          this.setState({ role: "" })
        }
      })
      firebase.database().ref(`/users/${firebase.auth().currentUser.uid}/mailingAddress`).on('value', value => {
        if (value.val() !== null) {
          this.setState({ mailingAddress: value.val() })
        } else {
          this.setState({ mailingAddress: "" })
        }
      })
    }
  }

  checkForBannedUsers = () => {
    if (firebase.auth().currentUser) {
      firebase.database().ref(`/users/${firebase.auth().currentUser.uid}/deleted`).on('value', value => {
        if (value.val() !== null && value.val() === true) {
          firebase.auth().signOut()
        }
      })
    }
  }


  openChat = () => window.Intercom('show')
  setSignedInState = user => {

    this.checkForBannedUsers()
    this.hasPhoneCheck()

    if (firebase.auth().currentUser) {
      window.Intercom('update', {
        app_id: "q9ojxf9p",
        email: firebase.auth().currentUser.email, // Email address
        user_id: firebase.auth().currentUser.uid, // Email address
      })

      Sentry.setUser({ email: firebase.auth().currentUser.email });
    }


    this.setState({ isSignedIn: !!user });
    if (!user) {
      this.setState({ isPro: false });
    } else {

      firebase
        .database()
        .ref(`/users/${user.uid}/Distributors`)
        .once("value")
        .then(snapshot => {
          this.setState({ Distributors: snapshot.val() });

        });
      firebase
        .database()
        .ref(`/users/${user.uid}/Distributor`)
        .once("value")
        .then(snapshot => {
          //console.log(snapshot.val())
          this.setDistributor(snapshot.val())

        });
      firebase
        .database()
        .ref(`/users/${user.uid}/statePreference`)
        .once("value")
        .then(snapshot => {
          this.setState({ DistributorStates: snapshot.val() ? Object.values(snapshot.val()) : [] });
        });

      firebase
        .database()
        .ref(`/users/${user.uid}/${process.env.REACT_APP_SLIM == "SLIM"? "pro-e" : process.env.REACT_APP_DRINKTYPE == "foodNew" ? "pro-r" : "pro-membership"}/active`)
        .on("value", snapshot => {
          this.setState({ isPro: snapshot.val() });
        });

      firebase
        .database()
        .ref(`/users/${user.uid}/distributor/handle`)
        .once("value")
        .then(snapshot => {

          this.setState({ distributor: snapshot.val() });
        });

      firebase
        .database()
        .ref(`/users/${user.uid}/owner/restaurants`)
        .once("value")
        .then(snapshot => {
          this.setState({ restaurantsOwned: snapshot.val() });
        });

      firebase
        .database()
        .ref(`/users/${user.uid}/cityPreference`)
        .once("value")
        .then(snapshot => {
          this.setCity(snapshot.val())
        });

      firebase
        .database()
        .ref(`/users/${user.uid}/cityPreferenceCoordinates`)
        .once("value")
        .then(snapshot => {
          this.setnearByLocation(snapshot.val())
        });

      firebase
        .database()
        .ref(`/users/${user.uid}/DRINKTYPE`)
        .once("value")
        .then(snapshot => {
          this.setState({ DRINKTYPE: snapshot.val() ? snapshot.val() : "wineNew" });
        });
    }



  };
  // Listen to the Firebase Auth state and set the local state.

  confirmSignIn = (url) => {
    if (firebase.auth().isSignInWithEmailLink(url)) {
      let email = window.localStorage.getItem('emailForSignIn');

      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }

      firebase.auth().signInWithEmailLink(email, url)
        .then()

    }
  }



  componentDidMount() {

    this.unregisterAuthObserver = () => { };

    this.client = Stitch.initializeDefaultAppClient("mrsomm-isvwz");

    const mongodb = this.client.getServiceClient(
      RemoteMongoClient.factory,
      "mongodb-atlas"
    );

    this.db = mongodb.db("mrsomm");

    this.client.auth
      .loginWithCredential(new UserPasswordCredential("david@somm.ai", process.env.REACT_APP_MONGO))

    this.confirmSignIn(window.location.href);

    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(user => this.setSignedInState(user));

    this.checkForBannedUsers()

    this.hasPhoneCheck()

  }

  setDistributor = (x) => {
    if (firebase.auth().currentUser) {
      firebase
        .database()
        .ref("users/")
        .child(firebase.auth().currentUser.uid)
        .update({ Distributor: x });

      firebase
        .database()
        .ref(`d/${x}/info`)
        .once("value")
        .then(snapshot => {
          this.setState({ DistributorInfo: snapshot.val(), Distributor: x })
        }
        )



      firebase
        .database()
        .ref(`d/${x}/Producers`)
        .once("value")
        .then(snapshot => {
          this.setState({ DistributorProducers: snapshot.val() });
        })

      firebase
        .database()
        .ref(`d/${x}/Restaurants`)
        .once("value")
        .then(snapshot => {
          this.setState({ defaultRestaurants: !!snapshot.val() ? snapshot.val() : {} });
        })

      firebase
        .database()
        .ref(`d/${x}/Distributors`)
        .once("value")
        .then(snapshot => {
          this.setState({ myDistributors: !!snapshot.val() ? snapshot.val() : {} });
        })

      firebase.database().ref(`/d/${x}/Team`)
        .once("value")
        .then(snapshot => {
          this.setState({
            Team: snapshot.val() ? Object.entries(snapshot.val())
              .filter(([x, y]) => y.active)
              .map(([x, y]) => ({ value: x, label: y.email })) : []
          })
        });




    }

  }


  setDistributorStatesState = x => {
    if (!!firebase.auth().currentUser) {
      firebase
        .database()
        .ref(`/users/${firebase.auth().currentUser.uid}`)
        .update(
          {
            statePreference: x,
          }
        )
    }
    this.setState({ DistributorStates: x })
  };





  componentDidUpdate(prevProps, prevState) {


  }



  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }






  removeSearch = itemId => {
    if (window.confirm('Confirm Deletion') && itemId) {
      const handle = firebase.auth().currentUser.uid;
      const itemRef = firebase
        .database()
        .ref(`/users/${handle}/newSavedSearches/${itemId}`);
      itemRef.remove();
    }
  };

  removeSharedSearch = (itemId, Distributor) => {
    if (window.confirm('Confirm Deletion') && Distributor && itemId) {
      const itemRef = firebase
        .database()
        .ref(`/d/${Distributor}/newSavedSearches/${itemId}`)
      itemRef.remove()
    };
  };

  setFreq = (savedSearchId, freq) => {
    if (freq && savedSearchId) {
      firebase
        .database()
        .ref(`/users/${firebase.auth().currentUser.uid}/newSavedSearches/${savedSearchId}`)
        .update(
          {
            freq: freq,
          }
        )
    }
  }

  nameSavedSharedSearch = (savedSharedSearchId, Distributor, name) => {
    if (name && savedSharedSearchId && Distributor) {
      firebase
        .database()
        .ref(`/d/${Distributor}/newSavedSearches/${savedSharedSearchId}`)
        .update(
          {
            name: name,
          }
        )
    }
  }

  nameSavedSearch = (savedSearchId, name) => {
    if (name && savedSearchId) {
      firebase
        .database()
        .ref(`/users/${firebase.auth().currentUser.uid}/newSavedSearches/${savedSearchId}`)
        .update(
          {
            name: name,
          }
        )
    }
  }

  setDRINKTYPE = (DRINKTYPE) => {
    this.setState({ DRINKTYPE: DRINKTYPE })
    firebase
      .database()
      .ref("users/")
      .child(firebase.auth().currentUser.uid)
      .update({ DRINKTYPE: DRINKTYPE })
  }

  render() {
    this.checkForBannedUsers()
    const { isSignedIn, uiConfig } = this.state;
    var sharedProps = {
      isMobile: window.innerWidth < 992,
      hasPhone: this.state.hasPhone,
      role: this.state.role,
      mailingAddress: this.state.mailingAddress,
      openChat: this.openChat,
      buy: this.state.buy,
      setBuy: () => this.setState({ buy: !this.state.buy }),
      isPro: this.state.isPro,
      distributor: this.state.distributor,
      Distributors: this.state.Distributors,
      Distributor: this.state.Distributor,
      DistributorInfo: this.state.DistributorInfo,
      DistributorProducers: this.state.DistributorProducers,
      defaultRestaurants: this.state.defaultRestaurants,
      DistributorStates: this.state.DistributorStates,
      DistributorTeam: this.state.Team ? this.state.Team : [],
      setDistributorStatesState: this.setDistributorStatesState,
      setDistributor: this.setDistributor,
      isSignedIn: this.state.isSignedIn,
      city: this.state.city,
      setnearByLocation: this.setnearByLocation,
      setnearByLocationArray: this.setnearByLocationArray,
      setnearByLocationArrayTogether: this.setnearByLocationArrayTogether,
      nearByLocation: this.state.nearByLocation,
      nearByLocationArray: this.state.nearByLocationArray,
      setCity: this.setCity,
      resetLocation: this.resetLocation,
      restaurant: this.state.restaurant,
      db: this.db,
      searchUpdate: {
        nameSavedSharedSearch: this.nameSavedSharedSearch,
        nameSavedSearch: this.nameSavedSearch,
        setFreq: this.setFreq,
        removeSearch: this.removeSearch,
        removeSharedSearch: this.removeSharedSearch,
      },
      baseComponents: baseComponents,
      baseFilter: baseFilter(process.env.REACT_APP_DRINKTYPE == "foodNew" ? "foodNew" : this.state.DRINKTYPE),
      settings: settings[process.env.REACT_APP_DRINKTYPE == "foodNew" ? "foodNew" : this.state.DRINKTYPE],
      DRINKTYPE: process.env.REACT_APP_DRINKTYPE == "foodNew" ? "foodNew" : this.state.DRINKTYPE,
      radius: this.state.radius,
      setRadius: this.setRadius,
      myDistributors: this.state.myDistributors,
      ReactiveBaseProps: {
        transformResponse: async function transformResponse(elasticsearchResponse, componentId) {

          try {
            console.log(elasticsearchResponse, "responseInitial", typeof elasticsearchResponse);

            const data = JSON.parse(JSON.stringify(elasticsearchResponse))

            const tr = (aggregations) => {
              if (typeof aggregations === "object") {
                for (const key in aggregations) {
                  if (aggregations.hasOwnProperty(key)) {
                    const value = aggregations[key];
                    if (typeof value === "object") {
                      if (value && "nested_ddelete" in value) {
                        const nestedCopy = value["nested_ddelete"]["filter_delete"];
                        delete value["nested_ddelete"];
                        aggregations[key] = { ...nestedCopy, ...value };
                      }
                    }
                  }
                }

                for (const key in aggregations) {
                  if (aggregations.hasOwnProperty(key)) {
                    const value = aggregations[key];
                    if (value && typeof value === "object" && "buckets" in value) {
                      for (let i = 0; i < value["buckets"].length; i++) {
                        const item = value["buckets"][i];
                        if ("reverse_nested_delete" in item) {
                          const reverseNestedCopy = item["reverse_nested_delete"];
                          delete item["reverse_nested_delete"];
                          value["buckets"][i] = { ...item, ...reverseNestedCopy };
                        }
                      }
                    }
                  }
                }

                for (const key in aggregations) {
                  if (aggregations.hasOwnProperty(key)) {
                    const value = aggregations[key];
                    tr(value);
                  }
                }
              }
            }

            tr(data);

            console.log(data, 'responseChanged')

            return data;
          } catch (e) {
            return elasticsearchResponse
          }
        },


        transformRequest: req => {
          const transform = t => {

            var lala = { ...t }

            try {





              const arr = lala.query.bool.must[0].bool.must

              const items = arr.filter(x => x.terms).map(x => x.terms).map(x => Object.entries(x)[0])
                .filter(([x, y]) => x.includes("wine_ids2"))
                .map(([x, y]) => ({ "terms": { [x.replace("wine_ids2", "wine_ids_nested")]: y } }))

              const filter = items.length > 0 ? ({
                "nested": {
                  "path": "wine_ids_nested",
                  "query": {
                    "bool": {
                      "filter": items
                    }
                  },
                }
              }) : ({
                "match_all": {}
              })



              lala.query.bool.must[0].bool.must = [
                ...arr,
                filter
              ]



              const tr = aggs => {
                let moving = {};
                for (const [key, value] of Object.entries(aggs)) {
                  if ("terms" in value && "field" in value.terms && value.terms.field.includes("wine_ids2")) {
                    value.terms.field = value.terms.field.replace("wine_ids2.", "wine_ids_nested.");
                    moving[key] = value;
                  }
                }

                for (const key of Object.keys(moving)) {
                  delete aggs[key];
                }

                for (const [key, value] of Object.entries(moving)) {
                  if ("aggs" in value) {
                    value.aggs = {
                      "reverse_nested_delete": {
                        "reverse_nested": {},
                        "aggs": value.aggs
                      }
                    };
                  }
                }

                if (Object.keys(moving).length) {
                  aggs.nested_ddelete = {
                    "nested": {
                      "path": "wine_ids_nested"
                    },
                    "aggs": {
                      "filter_delete": {
                        "filter": filter.nested ? filter.nested.query : ({
                          "match_all": {}
                        }),
                        "aggs": moving
                      }
                    }
                  };
                }


                for (const [key, value] of Object.entries(aggs)) {
                  if (value.aggs) {
                    tr(value.aggs)
                  }
                }



              }

              const convertToNested = (aggs) => {
                // Grab the filters

                const edittedAggs = JSON.parse(JSON.stringify(aggs).replace(/wine_ids2/g, "wine_ids_nested"))

                if (!!aggs.distributor.filters.filters) {
                  const distributor = edittedAggs.distributor
                  delete aggs.distributor
                  delete edittedAggs.distributor


                  aggs.nested_ddelete = {
                    nested: {
                      path: "wine_ids_nested"
                    },
                    aggs: {
                      ...edittedAggs,
                      distributor: {
                        ...distributor,
                        filters: {
                          filters: distributor.filters
                        },
                        aggs: {
                          ...distributor.aggs,
                          reverse_nested_delete: {
                            reverse_nested: {}
                          }
                        }
                      }
                    }
                  }


                }
              }



              tr(lala.aggs)
              removeNestedElements(lala)




              console.log(lala, 'aeflawe here2')
              return lala
            } catch (e) {
              return lala
            }
          }


          const body = req.body.split("\n").filter(x => x.length > 0).map(x => console.log(JSON.parse(x), "requestInitial") || JSON.parse(x))
            .map(x => transform(x))
            .map(x => console.log(x, 'requestChanged') || x)
            .map(x => JSON.stringify(x)).join("\n")
          return { ...req, body: body + "\n" }
        },
        setSearchParams: (key) => {
          let oldUrl = new URL(window.location.href)
          let url = new URL(key)
          console.log(url.pathname, url.search, url.hash)
          const newURL = url.pathname + url.search + oldUrl.hash
          if (window.location.href !== url.href) { window.history.replaceState({ path: newURL }, '', newURL) }
        },
        //mapKey: process.env.REACT_APP_FIREBASE,
        app: settings[process.env.REACT_APP_DRINKTYPE == "foodNew" ? "foodNew" : this.state.DRINKTYPE].index,
        url: this.state.isPro ? process.env.REACT_APP_ELASTIC_MRSOMM : "https://www.somm.ai",
        className: "search-page"
      }
    }
    const Main = process.env.REACT_APP_DRINKTYPE == "foodNew" ? MainEmerging : MainOriginal
    const Home = process.env.REACT_APP_DRINKTYPE == "foodNew" ? HomeEmerging : HomeOriginal

    if (!this.state.isSignedIn && process.env.REACT_APP_DRINKTYPE == "foodNew") {
      return <LoginEmerging {...this.props} {...sharedProps} key={Math.random()}></LoginEmerging>
    }

    if (!this.state.isSignedIn && process.env.REACT_APP_DRINKTYPE !== "foodNew") {
      return <Login {...this.props} {...sharedProps} key={Math.random()}></Login>
    }

    if (!window.location.href.includes("app.somm.ai") && !window.location.href.includes("slim.somm.ai") && !window.location.href.includes("www.fbmenu.ai") && !window.location.href.includes("localhost")) {
      return <Layout style={{ padding: '0px 0px 0px 24px' }}>
        <Row justify="space-around" align="middle" gutter={[16, 16]}>
          <Col md={8} sm={24} xs={24}>
            <span>
              <img className="navbar-logo" src={"/images/somm.png"} alt="" style={{ width: '200px' }} />
            </span>
            <br />            <br />            <br />
            <>We have changed our website so everything is now accessible through <a href="https://app.somm.ai">https://app.somm.ai</a>. Please go there, and set the portal to your preferred portal (e.g. US Spirits, EU Wine) on the top left.</>
          </Col>
          <Col md={16} sm={24} xs={24} style={{ height: "100vh", backgroundColor: "#960000" }}>
          </Col>
        </Row>
      </Layout>
    } else {

      return (




        this.db && this.client.auth.isLoggedIn ? <div className="">


          <Helmet>
            <meta charSet="utf-8" />
            <title>{`Somm.ai ${this.state.city ? this.state.city.split(",")[0] + " " : " "}`}</title>
            <meta name="description" content="On-premise Intelligence. Real-time data on over 47,000 on-premise accounts." />
            <meta property="og:title" content={`Somm.ai ${this.state.city ? this.state.city.split(",")[0] + " " : " "}`} />
            <meta property="og:description" content="On-premise Intelligence. Real-time data on over 47,000 on-premise accounts." />
            <meta property="og:image" content="/images/older/somm.png" />
          </Helmet>

          <Modal visible={!this.state.hasPhone}
            footer={null}
            closable={false}
          >
            {this.state.isSignedIn && <Profile {...sharedProps} />}
          </Modal>

          <Router>
            <Layout style={{ minHeight: '100vh' }}>

              <Sider {...sharedProps} {...this.props} setDRINKTYPE={this.setDRINKTYPE} />

              <Layout>
                <Layout.Content>
                  <Navigation
                    {...this.props}
                    {...sharedProps}
                  />

                  <main className="">

                    <Suspense fallback={<div>Loading...</div>}>
                      <Switch>
                        <Route path="/policies"><Policies /></Route>
                        {[
                          !this.state.isPro && process.env.REACT_APP_SLIM == "SLIM" &&
                          <Route
                            exact path="/"
                            component={(props) => <ProEnthusiast tryPro={this.tryPro} setSignedInState={this.setSignedInState}  {...sharedProps}
                              leader={<Landing
                                {...props}
                                {...sharedProps}
                                key={Math.random()}
                              />}
                            />
                            }
                          />,
                        ]}
                        {[
                          !this.state.isPro && process.env.REACT_APP_DRINKTYPE !== "foodNew" &&
                          <Route
                            exact path="/"
                            component={(props) => <Pro tryPro={this.tryPro} setSignedInState={this.setSignedInState}  {...sharedProps}
                              leader={<Landing
                                {...props}
                                {...sharedProps}
                                key={Math.random()}
                              />}
                            />
                            }
                          />,
                        ]}

                        {process.env.REACT_APP_SLIM == "SLIM" && <Route exact path="/" component={(props) => <Main {...props} {...sharedProps} key={Math.random()} />} />}

                        {hasWindow && this.state.isSignedIn && this.state.isPro
                          ? [
                            false && <Route exact path="/crm" component={(props) => <CRM {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/nas/:handle" component={(props) => <NationalAccountMongo {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/supplier/:handle" component={(props) => <Supplier {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/r/:restaurant" component={(props) => <MainRestaurant {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/w/:wine" component={(props) => <Main {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/p/:producer" component={(props) => <Main {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/s/:search" component={(props) => <Main {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/s" component={(props) => <Main {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/simone" component={(props) => <Simone {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/" component={(props) => <Home {...props} leading="/s/" {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/dashboard" component={(props) => <Dashboard {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/distributors" component={(props) => <Distributors {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/groups" component={(props) => <Groups {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/awards" component={(props) => <Awards {...props} {...sharedProps} key={Math.random()} />} />,
                            <Route exact path="/suppliers" component={(props) => <Suppliers {...props} {...sharedProps} key={Math.random()} />} />,
                          ]
                          : null}



                        {!this.state.isSignedIn && (
                          <Layout.Footer>
                            <Login
                              redirect={true}
                              redirectURL="/pro"
                              trigger=<h3>Please make a free account to continue</h3>
                            />

                            Too much commitment? You can <a href="#" onClick={this.openChat}>chat with us</a> or add your email to our mailing list below to get updates!
                            <Skeleton loading active
                              component={(props) => <MainRestaurant
                                {...props}
                                {...sharedProps}
                                key={Math.random()}

                              />
                              }
                            />
                          </Layout.Footer>)
                        }

                        {this.state.isSignedIn && !this.state.isPro && (
                          <center><h3><a href="https://pro.somm.ai/meetings/davidxmkong">Join Somm.ai Pro, Schedule a Demo</a></h3></center>
                        )}


                      </Switch>
                    </Suspense>
                    <Layout.Footer>
                      <Footer  {...sharedProps} />
                    </Layout.Footer>
                  </main>
                </Layout.Content>
              </Layout>
            </Layout>
          </Router>

        </div > : <>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <center>Please refresh your screen. If that does not work, please email us at hello@somm.ai.</center>
        </>

      );

    }


  }
}

export default SignInScreen;
