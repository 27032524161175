import { MacCommandFilled, SearchOutlined } from '@ant-design/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Avatar, Button, Input, Modal, Popover, Slider, Tag, Divider } from 'antd';
import firebase from "firebase";
import queryString from 'query-string';
import React, { Component } from "react";
import { Badge, DropdownButton, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Hotkeys from 'react-hot-keys';
import {
  getLatLng
} from 'react-places-autocomplete';
import { Link, Route, withRouter } from "react-router-dom";
import Popup from "reactjs-popup";
import googleToSomm from "./../js/googleToSomm";
import CommandBar from './CommandBar';
import Login from "./Login";
import GoogleMapsAutocomplete from "./main/components/GoogleMapsAutocomplete";



class Navigation extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    isSignedIn: firebase.auth().currentUser && this.props.isSignedIn,
    isPro: firebase.auth().currentUser && this.props.isPro,
    city: null,
    distributor: null,
    showCurrentLocation: false,
    showSuggestionsDropdown: true,
    pleaseCorrect: false,
    path: window.location.pathname.split("/")[1] || false,
    path2: window.location.pathname.split("/")[2] || false,
    pathDidChange: false,
    statePreference: this.props.DistributorStates,
    locationHistory: [],
    showSearch: false,
  };

  onChange = x => {
    this.setState({ search: x.target.value, isModalVisible: x.target.value.length > 0 })
  }
  onPressEnter = () => this.props.history.push("/s/" + this.state.search.replace("'", ""));


  componentDidMount() {

    if (!!firebase.auth().currentUser) {
      firebase
        .database()
        .ref(`/users/${firebase.auth().currentUser.uid}/locationHistory`)
        .once("value")
        .then(snapshot => {
          if (!!snapshot.val() && snapshot.val().length > 0) {
            this.setState({ locationHistory: snapshot.val() })
          }
        });
    }

    //listens for any route changes
    this.props.history.listen(() => {


      if (firebase.auth().currentUser) {
        firebase.database().ref(`activity/${firebase.auth().currentUser.uid}`).push({
          path: window.location.pathname,
          email: firebase.auth().currentUser.email,
          timestamp: firebase.database.ServerValue.TIMESTAMP,
        })
      }

      if (window.location.pathname.split("/")[1] || false) {
        this.setState({
          path: window.location.pathname.split("/")[1],
          path2: window.location.pathname.split("/")[2],
          pathDidChange: true,
          search: null,
        }, () => {
        })
      } else {
        this.setState({ path: "", path2: "", pathDidChange: true })
      }
      this.setState({ showSearch: false })
    })
  }

  transfer = () => {

  }

  componentDidUpdate(prevProps, prevState) {



    // Typical usage (don't forget to compare props):
    if (this.props.isPro !== prevProps.isPro) {
      this.setState({ isPro: firebase.auth().currentUser && this.props.isPro });
    }
    if (this.props.DistributorStates !== prevProps.DistributorStates) {
      this.setState({ statePreference: this.props.DistributorStates });
    }

    if (this.props.distributor !== prevProps.distributor) {
      this.setState({ distributor: this.props.distributor });
    }
    if (this.props.isSignedIn !== prevProps.isSignedIn) {
      this.setState({ isSignedIn: firebase.auth().currentUser && this.props.isSignedIn });
    }

    if (this.props.city !== prevProps.city) {
      if (this.props.city == "") {
        this.setState({
          address: ""
        });
      }
      this.setState({ city: this.props.city });
    }
  }

  handleAddressSelect2 = (results, close) => {
    googleToSomm(firebase.auth().currentUser.email, results, this.props.db, (todos) => this.props.history.push(`/r/${todos["_id"]}`))
    console.log(results,'here are the results')
    var locality = results[0].address_components.find(result => 
      (result.types.includes('locality') || result.types.includes('sublocality')) && 
    result.types.includes('political')
    ).short_name

    this.props.setCity(locality)
    this.setState({ address: locality })
    getLatLng(results[0]).then(
      (latLng) => {
        this.props.setnearByLocation(
          {
            lat: latLng.lat,
            lng: latLng.lng
          }
        )

        this.setState({
          showCurrentLocation: false,
          showSuggestionsDropdown: false
        }, close)
      }
    )
  }

  handleAddressSelectAdder = (results, close) => {
    getLatLng(results[0]).then(
      (latLng) => {
        this.props.setnearByLocationArray(
          {
            lat: latLng.lat,
            lng: latLng.lng
          }
        )
      }
    )
  }

  saveHistory = () => {
    const newItem = {
      statePreference: this.state.statePreference,
      city: this.state.city,
      nearByLocation: this.props.nearByLocation,
    }
    if (this.state.locationHistory.length > 0 && JSON.stringify(this.state.locationHistory[0]) == JSON.stringify(newItem)) {
      return
    } else {
      this.setState({
        locationHistory: [newItem].concat(this.state.locationHistory)
      }, () => {
        if (!!firebase.auth().currentUser) {
          firebase
            .database()
            .ref(`/users/${firebase.auth().currentUser.uid}`)
            .update(
              {
                locationHistory: this.state.locationHistory.slice(0, 5),
              }
            )
            .catch(err => console.log(err))
        }
      })

    }

  }


  editStates = (e, x) => {
    if (this.state.city) {
      this.props.setnearByLocation(null)
      this.setState({ city: null, address: "" })
    }
    if (this.state.statePreference.includes(x)) {
      this.setState({ statePreference: this.state.statePreference.filter(y => y !== x) }, () => this.props.setDistributorStatesState(this.state.statePreference))
    } else {
      this.setState({ statePreference: [x, ...this.state.statePreference] }, () => this.props.setDistributorStatesState(this.state.statePreference))

    }
  }

  clear = () => {
    if (this.state.city) {
      this.props.setnearByLocation(null)
      this.setState({ city: null, address: "" })
    }
    this.setState({ statePreference: [] })
    this.props.setDistributorStatesState([])
  }

  toggleDrawer = (event, open) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ right: open });
  };

  toggleShowSearch = () => this.state.showSearch ? this.setState({ showSearch: false }) : this.setState({ showSearch: true }, () => this.myRef.current && this.myRef.current.focus({
    cursor: 'end',
  }))

  showSearch = () => this.setState({ showSearch: true }, () => this.myRef.current && this.myRef.current.focus({
    cursor: 'end',
  }))




  render() {
    return <div
    >
      <Hotkeys
        keyName="command+k"
        onKeyDown={this.toggleShowSearch}
      />

      <Navbar>
        {this.state.path == "r" && this.state.pathDidChange && <div className="hideForLarge" onClick={() => this.props.history.goBack()}><ArrowBackIosIcon />Back</div>}
        <Navbar.Brand className="hideForSmall">
          <Link to="/">
            <img className="navbar-logo" src={process.env.REACT_APP_DRINKTYPE == "foodNew" ? "/images/F&B Insights.svg" : "/images/somm.png"} alt="" style={{ zIndex: 3 }} />
          </Link>
        </Navbar.Brand>


        <Input size="large" placeholder="Find Anything" prefix={<SearchOutlined />}
          className="d-print-none"
          onClick={this.showSearch}
          allowClear
          onChange={this.showSearch}
          onPressEnter={this.onPressEnter}
          //style={{ border: "1px solid #145aff", padding: "5px", margin: "5px", borderRadius: "0px" }}
          value={""}
          suffix={<><MacCommandFilled />+K</>}
          addonAfter={<Popup
            onClose={this.saveHistory}
            modal
            lockScroll
            contentStyle={{ minWidth: "300px", overflowY: 'scroll', margin: '10px auto' }}
            trigger={<div style={{}}>
              <a href="javascript:void(0)" >{
                this.props.nearByLocationArray.length > 1
                  ? "📍 Map"
                  : !!this.props.nearByLocationArray[0]
                    ? "📍 " + (this.state.city === null ? `${this.props.nearByLocationArray[0].lat},${this.props.nearByLocationArray[0].lng}` : this.state.city)
                    : this.state.statePreference.length > 0
                      ? "📍 " + this.state.statePreference.join(", ")
                      : "📍 Global ▾"
              }</a></div>}
            position="bottom right">
            {close => window.location.href.includes("saved=") || window.location.href.includes("sharedSaved=") ? <>
              <div style={{ whiteSpace: "normal", textAlign: "left" }}>
                You are currently viewing a saved search. To change locations, we will exit you out of your saved search.
                You can then change your location.
                Your other search parameters will remain.
              </div>

              <Button style={{ float: "left" }} onClick={close}>
                Cancel
              </Button>
              <Button type="primary" style={{ float: "right" }} onClick={
                () => {
                  var url = new URL(window.location);
                  url.searchParams.delete("saved");
                  url.searchParams.delete("sharedSaved");
                  this.props.history.push((url.pathname + url.search));
                }
              }>
                Exit Current Saved Search
              </Button>
            </> : <>
              <div style={{ whiteSpace: "normal", textAlign: "left" }}>

                <div style={{}}>Location Set To <span href="javascript:void(0)" >{
                  this.props.nearByLocationArray.length > 1
                    ? "📍 See Map Below"
                    : !!this.props.nearByLocationArray[0]
                      ? <>{`📍 ${(this.state.city === null ? `${this.props.nearByLocationArray[0].lat},${this.props.nearByLocationArray[0].lng}` : this.state.city)} (${this.props.radius}mi/${parseInt(this.props.radius * 1.6)}km radius)`}
                        <Slider value={this.props.radius} onChange={this.props.setRadius} /></>
                      : this.state.statePreference.length > 0
                        ? "📍 " + this.state.statePreference.join(", ")
                        : "📍 Global ▾"
                }</span></div>

                {this.props.nearByLocationArray.length > 0 && this.props.nearByLocationArray[0] && <img style={{ height: "100%", width: "100%" }} src={`https://www.mapquestapi.com/staticmap/v5/map?key=it4AZwNAWFrjmvx5YgasiS4IUWmdaZpU&${this.props.nearByLocationArray.map(x => `shape=${`${x.lat},${x.lng}`}|radius:${this.props.radius}mi&size=1100,500@2x`).join("&")}`
                } />}

                <br />
                {window.google && <GoogleMapsAutocomplete geolocate {...this.props} callback={this.handleAddressSelect2} adder={this.handleAddressSelectAdder} />}

                <br />
                {["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DC", "DE", "FL", "GA",
                  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
                  "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
                  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
                  "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY",
                  "GB", "FR", "DE", "CH", "AT", "ON", "BC", "QC", "AB"
                ].map(x => <>
                  <Button key={x}

                    type={this.state.statePreference.includes(x) ? "primary" : null}
                    size="small"
                    value={x} onClick={(e) => this.editStates(e, x)}>
                    {x}
                  </Button>{" "}</>)}
                <br />
                <br />
                {this.state.locationHistory.length > 1 && <Divider orientation={'left'}>Recent Locations</Divider>}
                {this.state.locationHistory.slice(1, 4).map(x => <li style={{ cursor: "pointer" }} onClick={
                  () => {
                    if (!!x.statePreference && x.statePreference.length > 0) {
                      this.props.setnearByLocation(null)
                      this.setState({ city: null, address: "" })
                      this.setState({ statePreference: x.statePreference }, () => this.props.setDistributorStatesState(this.state.statePreference))
                    } else if (!!x.city) {
                      this.props.setnearByLocation(x.nearByLocation)
                      this.props.setCity(x.city)
                    }
                  }
                }>{
                    !!x.city
                      ? "📍 " + x.city.split(",")[0]
                      : !!x.statePreference && x.statePreference.length > 0
                        ? "📍 " + x.statePreference.join(", ") : "Global"
                  }</li>)}

                
                <br />
                <Button style={{ float: "left" }} onClick={this.clear}>
                  Clear Location Filters
                </Button>
                <Button type="primary" style={{ float: "right" }} onClick={close}>
                  Done
                </Button>
              </div>
            </>}
          </Popup>}
        />

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        {this.state.isSignedIn && firebase.auth().currentUser
          ? this.props.isPro
            ? null
            : <Nav.Link style={{ whiteSpace: "nowrap", }} onClick={() => { firebase.auth().signOut() }}>
              Logout
            </Nav.Link>
          : <Login redirect={this.transfer} trigger={<Nav.Link style={{ whiteSpace: "nowrap", }} >
            Login | Sign Up
          </Nav.Link>} />
        }
      </Navbar>
      
      {this.state.showSearch&&<Modal 
      open={this.state.showSearch}
        forceRender
        okText={"Full Text Wine Search"}
        onCancel={() => this.setState({ showSearch: false })}
        onClose={() => this.setState({ showSearch: false })}
        width="80vw"
        closable={false}
        footer={null}
        maskClosable={true}
        afterClose={() => console.log("afterClose")||this.setState({ showSearch: false })}
      >
        <CommandBar myRef={this.myRef} {...this.props} path2={this.state.path2} path={this.state.path} />
      </Modal>}
    </div>
  }
}

export default withRouter(Navigation)
